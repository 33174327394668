<template>
	<div>
		<BlockUI :message="msg" :html="html" v-if="isLoading"></BlockUI>
		<CCard class="p-4">
			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow>
						<CCol lg="6">
							<h5 v-if="!editMode">Create Booking</h5>
							<h5 v-else>Update Booking / {{dataParams.booking_no}}</h5>
						</CCol>
				     	<CCol lg="6">
				      		<div style="text-align: right">
				      			<CButton size="sm" @click="backToTable()" class="btn btn-ghost-dark"><i class="fa fa-close"></i> Cancel</CButton> &nbsp;
					      		<CButton v-if="!hasError" size="sm" class="px-3" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
								<CButton v-else disabled size="sm" class="px-3" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
				      		</div>
						</CCol>
						
					</CRow>
				</CCardHeader>
				<CCardBody>
					<fieldset class="sub-fieldset">
						<legend>Booking Information</legend>

						
						<CRow class="ml-3 mr-3">
							
							<CCol lg="8">
								<div class="form-group"> 
									<label class="mb-1"> Customer <span class="text-danger">*</span></label>
									<v-select 
										@input="computeclientrate"
										label="customer_name" 
										:options="customerList.data"
										:reduce="item => item.id"
										v-model="dataParams.customer_id"
										placeholder="-Select-" 
										:disabled="
											disable_when(['company admin','finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
											disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
											disable_when(['pod', 'pod + dispatcher'],['dispatched','delivered','completed'].indexOf(dataParams.status) > -1)
										"
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.customer_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1"> Booking Type <span class="text-danger">*</span></label>
									<v-select 
										@input="computeclientrate"
										label="setting_name" 
										:options="bookingTypeList.data"
										:reduce="item => item.id"
										v-model="dataParams.booking_type_id"
										placeholder="-Select-" 
										:disabled="
											disable_when(['company admin','finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
											disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
											disable_when(['pod','pod + dispatcher'],['dispatched','delivered','completed'].indexOf(dataParams.status) > -1)
										"
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.booking_type_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
							
						</CRow>
						<CRow class="ml-3 mr-3"> 
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1"> Delivery Date
										<span class="text-danger"> *
										</span>
									</label>
								
									
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.delivery_date_f"
										:typeable="true" 
										required 
										:disabled="
											disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
											disable_when(['finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
											disable_when(['pod'],[,'dispatched','delivered','completed'].indexOf(dataParams.status) > -1)
										"
									>
									</Datepicker>  
								</div>
							</CCol> 
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1"> Depot <span class="text-danger"> *</span></label>
									<v-select 
										@input="computeclientrate"
										label="setting_name" 
										:options="depotList.data"
										:reduce="item => item.id"
										v-model="dataParams.depot_id"
										placeholder="-Select-" 
										:disabled="JSON.parse(window.localStorage.getItem('user_data')).depot_id == null ? false : true"
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.depot_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1"> Commodity Type <span class="text-danger"> *</span></label>
									<v-select 
										@input="computeclientrate"
										label="setting_name" 
										:options="commodityTypeList.data"
										:reduce="item => item.id"
										v-model="dataParams.commodity_id"
										placeholder="-Select-" 
										:disabled="
											disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
											disable_when(['finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
											disable_when(['pod'],['delivered','completed'].indexOf(dataParams.status) > -1)
										"
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.commodity_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
							
						</CRow>
						<CRow class="ml-3 mr-3">
						
						<CCol lg="4">
							<div class="form-group"> 
								<label class="mb-1"> Route Code</label>
								<CInput
									 v-model="dataParams.route_code" 
								/>
							</div>
						</CCol>
						<CCol lg="4">
							<label class="mb-1"> No. of Trips</label>
							<CInput
								:disabled="is_roadwise()"
								type="number"
								v-model="dataParams.no_of_trips" 
							/>
						</CCol>
						<CCol lg="4">
							<label class="mb-1"> No. of Drops</label>
							<CInput
								type="number"
								v-model="dataParams.no_of_drops" 
							/>
						</CCol>
					</CRow>
						<CRow class="ml-3 mr-3">
							
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1"> Origin <span class="text-danger"> *</span></label>
									<v-select 
										@input="computeclientrate"
										label="setting_name" 
										:options="originList.data"
										:reduce="item => item.id"
										v-model="dataParams.origin_id"
										placeholder="-Select-" 
										:disabled="
											disable_when(['company admin','finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
											disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
											disable_when(['pod','pod + dispatcher'],['dispatched','delivered','completed'].indexOf(dataParams.status) > -1)
										"
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.origin_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>
							<!-- <CCol lg="4"  v-if="company_type.toLowerCase() == 'trucking with agency' && dataParams.status == 'completed'"> -->
								<CCol lg="4" >
								<div class="form-group" v-if="(is_trucking_agency() && ['delivered','completed'].indexOf(dataParams.status) >= 0) || !is_trucking_agency()"> 
									<label class="mb-1"> Destination</label>
									<!-- <v-select 
										@input="$event => computeclientrate($event, 'destination')"
										label="setting_name" 
										:disabled="computing_client_rate"
										:options="destinationList.data"
										v-model="dataParams.destination_label"
										placeholder="-Select-" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select> -->
									<v-select label="destination" 
										:filterable="false" 
										:options="options" 
										:disabled="computing_client_rate"
										@search="onSearch" 
										@input="$event => computeclientrate($event, 'destination')"
										v-model="dataParams.destination_label"
										>
											<template slot="no-options">
											Type to search destination. (minimum 3 characters)
											</template>
											<template slot="option" slot-scope="option">
											<div class="d-center">
												{{ option.destination }}
												</div>
											</template>
											<template slot="selected-option" slot-scope="option">
											<div class="selected d-center">
												{{ option.destination }}
											</div>
											</template>
											<template #search="{attributes, events}">
											<input
												class="vs__search"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
										</v-select>
								</div>
							</CCol> 
							
								

						</CRow>
						
					</fieldset>
					
					<fieldset class="sub-fieldset">
						<legend>Vehicle Assignment</legend>
							<CRow class="ml-3 mr-3">
								<CCol lg="12">
								<div class="form-group">  
									<CInputCheckbox 
										@change="subconChanged"
										:label="'Subcon'"
										:checked.sync="dataParams.is_subcon" 
					                    :inline="true" 
										:disabled="
											disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
											disable_when(['finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
											disable_when(['pod','pod + dispatcher'],[,'dispatched','delivered','completed'].indexOf(dataParams.status) > -1)
										"
				                    /> 
								</div>
							</CCol>
								<CCol lg="4">
									<div class="form-group"> 
										<label class="mb-1"> Vehicle No. <span class="text-danger"> *</span></label>
										<v-select 
											label="plate_no"
											:options="vehicleList.data"
											:value="dataParams.vehicle_info"
											placeholder="-Select-"  
											id="vehicle_no"
											@input="vehicleChange"
											:disabled="
											disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
											disable_when(['finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
											disable_when(['pod','pod + dispatcher'],['dispatched','delivered','completed'].indexOf(dataParams.status) > -1)
											"
										>
											 <template #search="{attributes, events}">
												<input
													class="vs__search"
													:required="!dataParams.vehicle_id"
													v-bind="attributes"
													v-on="events"
												/>
											</template> 
										</v-select>
									</div>
								</CCol>
								<CCol lg="4">
									<div class="form-group"> 
										<label class="mb-1"> Truck Type<span class="text-danger"> *</span></label>
										<v-select 
											@input="computeclientrate"
											label="setting_name" 
											:options="vehicleTypeList.data"
											:reduce="item => item.id"
											v-model="dataParams.vehicle_type_id"
											placeholder="-Select-" 
											:disabled="
											disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
											disable_when(['finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
											disable_when(['pod','pod + dispatcher'],['dispatched','delivered','completed'].indexOf(dataParams.status) > -1)
											"
											
										>
											<template #search="{attributes, events}">
												<input
													class="vs__search"
													:required="!dataParams.vehicle_type_id"
													v-bind="attributes"
													v-on="events"
												/>
											</template>
										</v-select>
									</div>
								</CCol> 
							</CRow>
						<!-- SUBCON VENDOR TRUCKING -->
							<div v-if="dataParams.is_subcon">
								<CRow class="ml-3 mr-3"> 
									<CCol lg="8">
										<div class="form-group"> 
											<label class="mb-1"> Vendor <span class="text-danger">* </span></label>
											<v-select 
												label="label" 
												:options="vendorList.data"
												:reduce="item => item.id"
												v-model="dataParams.vendor_id"
												placeholder="-Select-" 
												@input="vendorChange"
												:disabled="
												disable_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) ||
												disable_when(['finance manager'],['delivered'].indexOf(dataParams.status) > -1) ||
												disable_when(['pod','pod + dispatcher'],['dispatched','delivered','completed'].indexOf(dataParams.status) > -1)
											"
												
											>
												<template #search="{attributes, events}">
													<input
														class="vs__search"
														:required="!dataParams.vendor_id"
														v-bind="attributes"
														v-on="events"
													/>
												</template>
											</v-select>
										</div>
									</CCol>
									
								</CRow>
							</div>
					</fieldset>
					<fieldset class="sub-fieldset">
						<legend>Personnel Assignment</legend>
						<!-- ASSIGNING PERSONNEL -->
                        <CRow >
                            <CCol lg="4" md="4">
                                <AssignedPersonnelTemplate :required="false" :value="dataParams.assigned_personnel" v-if="company_type.toLowerCase() != 'trucking with no agency'" :title="'Assigned Personnel - Driver'" @changed="setPersonnelTypeDriver" />
                                <CRow>
                                    <CCol lg="12">
                                        <div v-if="dataParams.assigned_personnel == 'outsourced'">
                                            <CRow class="ml-3 mr-3">
                                                <CCol lg="12">
                                                    <div class="form-group"> 
                                                        <label class="mb-1">  {{ assigned_personnel_title }} <span class="text-danger"> *</span></label>
                                                        <v-select 
                                                            label="vendor_name" 
                                                            :options="agencyList.data"
                                                            :value="selected_driver_agency"
                                                            placeholder="-Select-"
                                                            @input="agencyChangeDriver"
                                                        >
                                                            <template #search="{attributes, events}">
                                                                <input
                                                                    class="vs__search"
                                                                    :required="!dataParams.agency"
                                                                    v-bind="attributes"
                                                                    v-on="events"
                                                                />
                                                            </template>
                                                        </v-select>
                                                    </div>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <CRow class="ml-3 mr-3"> 
                                            <CCol lg="12">
                                                <div class="form-group">
                                                <!-- <div class="form-group" v-if="company_type.toLowerCase() == 'trucking with agency'">  -->
                                                    <label class="mb-1">  {{ driver_title }} <span class="text-danger">* </span></label>
                                                    
                                                    <v-select 
                                                        label="name" 
                                                        :options="driverList.data"
                                                        :value="selected_driver"
                                                        placeholder="-Select-"
														@input="$event => driverChange($event)" 
													
                                                    >
                                                        <template #search="{attributes, events}">
                                                            <input
                                                                class="vs__search"
                                                                :required="!dataParams.driver_id"
                                                                v-bind="attributes"
                                                                v-on="events"
                                                            />
                                                        </template>
                                                    </v-select>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol lg="4" md="4">
                                <AssignedPersonnelTemplate :required="false" :value="dataParams.assigned_personnel_helper_1" v-if="company_type.toLowerCase() != 'trucking with no agency'" :title="'Assigned Personnel - Helper 1'" @changed="setPersonnelTypeHelper1" />
                                <CRow>
                                    <CCol lg="12">
                                        <div v-if="dataParams.assigned_personnel_helper_1 == 'outsourced'">
                                            <CRow class="ml-3 mr-3">
                                                <CCol lg="12">
                                                    <div class="form-group"> 
                                                        <label class="mb-1">  {{ assigned_personnel_title }}</label>
														
                                                        <v-select 
                                                            label="vendor_name" 
                                                            :options="agencyList.data"
                                                            :value="selected_helper_a_agency"
                                                            placeholder="-Select-"
                                                            @input="agencyChangeHelper1"
                                                        >
                                                            <template #search="{attributes, events}">
                                                                <input
                                                                    class="vs__search"
                                                                    v-bind="attributes"
                                                                    v-on="events"
                                                                />
                                                            </template>
                                                        </v-select>
                                                    </div>
                                                </CCol>
                                            </CRow>
                                        </div>
                                        <CRow class="ml-3 mr-3">
                                            <CCol lg="12">
                                                <div class="form-group"> 
                                                <label class="mb-1">  {{ helper1_title }} </label>
								
                                                <v-select 
                                                    label="name" 
                                                    :options="helperList.data"
                                                    :value="selected_helper_a"
                                                    placeholder="-Select-" 
													@input="helper1Change"
                                                >
													<template #search="{attributes, events}">
														<input
															class="vs__search"
															:required="dataParams.agency_id_helper1 && !dataParams.helper_a_id"
															v-bind="attributes"
															v-on="events"
														/>
													</template>
                                                    
                                                </v-select>
                                            </div>
                                            </CCol>
                                        </CRow>
										<CRow class="ml-3 mr-3" style="margin-top: -25px">
											<CCol lg="12">
												<div class="form-group">
												<label for="exampleInputEmail1"> </label>
												<div class="form-inline">
													<CInputCheckbox 
														:label="'Driver Included?'"
														:checked.sync="dataParams.driver_included1"
														@change="driverIncluded(1)" 
													/>
													<i class="fa fa-question-circle ml-2" title="Check 'driver included' to include driver in dropdown list." ></i>
												</div> 
												 
											</div>
											</CCol>
										</CRow>
                                    </CCol>				
                                </CRow>
                            </CCol>
                            <CCol lg="4" md="4">
                                <AssignedPersonnelTemplate :required="false" :value="dataParams.assigned_personnel_helper_2" v-if="company_type.toLowerCase() != 'trucking with no agency'" :title="'Assigned Personnel - Helper 2'" @changed="setPersonnelTypeHelper2" />
                                <CRow>
                                    <CCol lg="12">
                                        <div v-if="dataParams.assigned_personnel_helper_2 == 'outsourced'">
                                            <CRow class="ml-3 mr-3">
                                                <CCol lg="12">
                                                    <div class="form-group"> 
                                                        <label class="mb-1">  {{ assigned_personnel_title }}</label>
                                                        <v-select 
                                                            label="vendor_name" 
                                                            :options="agencyList.data"
                                                            :value="selected_helper_b_agency"
                                                            placeholder="-Select-"
                                                            @input="agencyChangeHelper2"
                                                        >
                                                            <template #search="{attributes, events}">
                                                                <input
                                                                    class="vs__search"
                                                                    
                                                                    v-bind="attributes"
                                                                    v-on="events"
                                                                />
                                                            </template>
                                                        </v-select>
                                                    </div>
                                                </CCol>
                                            </CRow>
                                            
                                        </div>
                                        <CRow class="ml-3 mr-3">
                                            <CCol lg="12">
                                                <div class="form-group"> 
                                                <label class="mb-1">  {{helper2_title}} </label>
												
                                                <v-select 
                                                    label="name" 
                                                    :options="helperList2.data"
                                                    :value="selected_helper_b"
                                                    placeholder="-Select-" 
													@input="helper2Change"
                                                >

												<template #search="{attributes, events}">
														<input
															class="vs__search"
															:required="dataParams.agency_id_helper2 && !dataParams.helper_b_id"
															v-bind="attributes"
															v-on="events"
														/>
													</template>
                                                    
                                                </v-select>
                                            </div>
                                            </CCol>
                                        </CRow>
				
										<CRow class="ml-3 mr-3" style="margin-top: -25px">
											<CCol lg="12">
												<div class="form-group">
												<label for="exampleInputEmail1"> </label> 
												<div class="form-inline">
												<CInputCheckbox 
													:label="'Driver Included?'"
													:checked.sync="dataParams.driver_included2"
													@change="driverIncluded(2)" 
												/> 
												<i class="fa fa-question-circle ml-2" title="Check 'driver included' to include driver in dropdown list." ></i>
											</div>
											</div>
											</CCol>
										</CRow>
									
                                    </CCol>
                                </CRow>
							
                                    
                            </CCol>
                        </CRow>
							<CRow class="ml-3 mr-3">
									<CCol lg="12">
									<CTextarea
										label="Remarks"
										placeholder="Maximum of 255 only."
										maxlength="255"
										v-model="dataParams.remarks" 
										:disabled="
										disable_when(['dispatcher','pod','finance staff','fleet staff','fleet manager','rw hr staff'],['completed'].indexOf(dataParams.status) > -1) 
									
										
										"
									/>
								</CCol> 
							</CRow>
						
                        
						
					</fieldset>
					<fieldset class="sub-fieldset">
					<legend> Item Details</legend>
						<CRow class="ml-3 mr-3" v-for="(item, index) in dataParams.booking_item_details" :key="index"> 
							<CCol lg="12" v-if="index >= 1">
								<hr style="border-top: dotted 1px;" />
							</CCol>
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mr-2 mb-1">Item Type </label>
									<v-select 
									
										label="setting_name" 
										:options="bookingItemTypeList.data"
										:reduce="item => item.id"
										v-model="dataParams.booking_item_details[index].item_type"
										placeholder="-Select-" 
										
									>
										<!-- <template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.booking_item_details[index].item_type"
												v-bind="attributes"
												v-on="events"
											/>
										</template> -->
									</v-select>
								</div>
							</CCol> 
							
							<CCol lg="8">
								<label class="mb-1">Item Description</label>
								<CInput
				                 
				                  v-model="dataParams.booking_item_details[index].description"   
				                 
				                />
							</CCol>
							
								<CCol lg="3">
								<label class="mb-1">Length (cm)</label>
								<CInput
				                	v-model="dataParams.booking_item_details[index].length"
									
				                 
				                />
							</CCol>
							<CCol lg="3">
								<label class="mb-1">Width (cm)</label>
								<CInput
				                 
				                	v-model="dataParams.booking_item_details[index].width"
				                
				                />
							</CCol>
							<CCol lg="3">
								<label class="mb-1">Height (cm)</label>
								<CInput
				                 
				                	v-model="dataParams.booking_item_details[index].height"
				                  
				                />
							</CCol>
							<CCol lg="2">
								<label class="mb-1">Weight (kg)</label>
								<CInput
				                 
				                	v-model="dataParams.booking_item_details[index].weight"
				                
				                />
							</CCol>
							<CCol lg="1" v-if="index != 0"> 
								<br/>
								<CButton size="sm" color="danger mt-2" @click="deleteItem(index)"><i class="fa fa-trash"></i></CButton> 
							</CCol>
						</CRow>
						<CRow class="ml-3 mr-3">
							<CCol lg="12">
								
								<CButton size="sm" color="success" @click="addItem"><i class="fa fa-plus"></i> New Item</CButton>
								<CButton  v-if="dataParams.booking_item_details.length > 1" size="sm" color="danger" @click="deleteAll" class="ml-2"><i class="fa fa-times"></i> Delete All</CButton>
								<b class="float-right">TOTAL WEIGHT: {{ total_weight }}</b>
							</CCol>
						</CRow>
						<br/>
						
						
						
					</fieldset>
					<fieldset class="sub-fieldset">
						<legend>References</legend>
						<!--IF STATUS IS COMPLETED-->
						<CRow class="ml-3 mr-3">
							<CCol lg="3">
								<CInput
				                  label="Client Ref No."
				                 	v-model="dataParams.client_ref_no" 
				                />
							</CCol>
							<CCol lg="3">
								<CInput
				                  label="Other Ref No."
				                  v-model="dataParams.other_ref_no" 
				                />
							</CCol>
						</CRow>
						<CRow class="ml-3 mr-3">
							<CCol lg="3">
								
								<CInput
								@focus="$event.target.select()"
                    			:class="{'has-error' : error_list['fuel']}"
				                  label="Fuel (L)"
				                  v-model="dataParams.fuel" 
								   @keypress="isNumber($event)"
				                />
								<small v-if="error_list['fuel']" class="text-danger" style="font-size:10px">{{ error_list['fuel'] }}</small>
							</CCol> 
						
							<CCol lg="3">
								<CInput
				                  label="Fuel Po."
				                  v-model="dataParams.fuel_po" 
				                />
							</CCol>
							<CCol lg="3">
								<CInput
								@focus="$event.target.select()"
                    			:class="{'has-error' : error_list['fuel-amount']}"
				                  label="Fuel Amount"
				                  placeholder="0.00"
				                  v-model="dataParams.fuel_amount" 
								   @keypress="isNumber($event)"
				                />
								<small v-if="error_list['fuel-amount']" class="text-danger" style="font-size:10px">{{ error_list['fuel-amount'] }}</small>
							</CCol>
						</CRow>
						<CRow class="ml-3 mr-3">
							<CCol lg="12">
								<CInput
				                  label="Remarks 2"
				                	v-model="dataParams.remarks_b" 
				                />
							</CCol> 
						</CRow>
					</fieldset>

					<fieldset class="sub-fieldset">
						<legend> Booking Photos</legend>
						<div id="default-img-view" v-show="false">
							<img src="@/assets/IMAGES/upload-image.png">
							<span class=""><em>Click to select image</em></span>
						</div>
						<CRow class="ml-3 mr-3 img-upload-container">
							<CCol lg="3" class="text-center pb-2">
								<label for="input-file1" id="file-area1" class="btn_upload mr-3">
									<input type="file" accept="image/*" id="input-file1" hidden>
									<div id="img-view1" class="img-view">
										<img src="@/assets/IMAGES/upload-image.png">
										<span class=""><em>Click to select image</em></span>	
									</div>
									
								</label>
								<br>
								<span v-if="image_list[0] != null" class="text-danger mb-4" @click="deleteImage(0)" style="font-size:12px;cursor:pointer">
									<em>Click to delete image</em>
								</span>
								<span v-else class="text-danger mb-4" style="font-size:12px;cursor:pointer">
									<em>&nbsp;</em>
								</span>
								
							</CCol> 
							<CCol lg="3"  class="text-center pb-2">
								<label for="input-file2" class="btn_upload mr-3" id="file-area2">
									<input type="file" accept="image/*" id="input-file2" hidden>
									<div id="img-view2"  class="img-view">
										<img src="@/assets/IMAGES/upload-image.png">
										<span class=""><em>Click to select image</em></span>
									</div>
								</label>
								<br>
								<span v-if="image_list[1] != null" @click="deleteImage(1)" class="text-danger mb-4" style="font-size:12px;cursor:pointer">
									<em>Click to delete image</em>
								</span>
								<span v-else class="text-danger mb-4" style="font-size:12px;cursor:pointer">
									<em>&nbsp;</em>
								</span>
							</CCol>
							<CCol lg="3" class="text-center pb-2">
								<label for="input-file3" id="file-area3" class="btn_upload">
									<input type="file" accept="image/*" id="input-file3" hidden>
									<div id="img-view3" class="img-view">
										<img src="@/assets/IMAGES/upload-image.png">
										<span class=""><em>Click to select image</em></span>
									</div>
									
								</label>
								<br>
								<span v-if="image_list[2] != null" @click="deleteImage(2)" class="text-danger mb-4" style="font-size:12px;cursor:pointer">
									<em>Click to delete image</em>
								</span>
								<span v-else class="text-danger mb-4" style="font-size:12px;cursor:pointer">
									<em>&nbsp;</em>
								</span>
							</CCol>
						</CRow>
					</fieldset>
					<fieldset class="sub-fieldset" v-if="dataParams.status == 'completed'">
						<legend>Pricing Details</legend>
				<!--IF STATUS IS COMPLETED-->
					<CRow class="ml-3 mr-3" >
					
							<CCol lg="6">
								
								<label>Client Rate <em class="ml-5" v-if="suggested_rate > 0">Suggested client rate: <strong style="cursor: pointer;" title="Click to use" @click="useSuggestedCr" class="text-primary">{{ suggested_rate | number_format('currency',2) }}</strong>  <i class="fa fa-info-circle" title="Client rate based on selected Depot, Destination, Customer, Vehicel type, Commodity and Origin"></i></em></label>
								<input class="form-control mb-3"  @focus="$event.target.select()" v-model="dataParams.client_rate" @keypress="isNumber($event)"  />
								<!-- <CInput
				                  label="Client Rate"
								  @focus="$event.target.select()"
				                	v-model="dataParams.client_rate" 
									@keypress="isNumber($event)"

				                /> -->
								
							</CCol>
							<CCol lg="6">
								<strong style="font-size:20px"> Total Rate <br>{{(dataParams.client_rate * dataParams.no_of_trips) | number_format('currency',2)}}</strong>
							</CCol>

							<CCol lg="6" v-if="dataParams.is_subcon">
							<CInput
								label="Subcon Rate"
								placeholder="00.00"
								@focus="$event.target.select()"
								v-model="dataParams.subcon_rate" 
								@keypress="isNumber($event)"
							/>
						</CCol>
						<CCol lg="6" v-if="dataParams.is_subcon">
						</CCol>
							<CCol lg="6">
								<CInput
									:class="{'has-error' : error_list['driver-rate']}"
									@focus="$event.target.select()"
				                 	label="Driver Rate"
				                 	v-model="dataParams.driver_rate" 
									@keypress="isNumber($event)"
				                />
								<small v-if="error_list['driver-rate']" class="text-danger" style="font-size:10px">{{ error_list['driver-rate'] }}</small>
							</CCol>
						
							<CCol lg="6">
								<CInput
								@focus="$event.target.select()"
                    			:class="{'has-error' : error_list['driver-allowance']}"
				                  label="Driver Allowance"
				                 	v-model="dataParams.driver_allowance" 
									@keypress="isNumber($event)"
				                />
								<small v-if="error_list['driver-allowance']" class="text-danger" style="font-size:10px">{{ error_list['driver-allowance'] }}</small>
							</CCol>
						
							<CCol lg="6">
								<CInput
									v-if="dataParams.helper_a_id"
									@focus="$event.target.select()"
									label="Helper 1 Rate"
									:class="{'has-error' : error_list['helper1-rate']}"
				                 	v-model="dataParams.helper_a_rate"
									@keypress="isNumber($event)"
				                />
								<small v-if="error_list['helper1-rate']" class="text-danger" style="font-size:10px">{{ error_list['helper1-rate'] }}</small>
							</CCol>
							<CCol lg="6">
								<CInput
								@focus="$event.target.select()"
                    			:class="{'has-error' : error_list['helper1-allowance']}"
								v-if="dataParams.helper_a_id"
				                  label="Helper 1 Allowance"
				                  
				                 	v-model="dataParams.helper_a_allowance" 
									@keypress="isNumber($event)"
				                />
								<small v-if="error_list['helper1-allowance']" class="text-danger" style="font-size:10px">{{ error_list['helper1-allowance'] }}</small>
							</CCol>
						
						
							<CCol lg="6">
								<CInput
								  v-if="dataParams.helper_b_id"
				                  label="Helper 2 Rate"
								  :class="{'has-error' : error_list['helper2-rate']}"
				                 	v-model="dataParams.helper_b_rate" 
									@keypress="isNumber($event)"	   
				                />
								<small v-if="error_list['helper2-rate']" class="text-danger" style="font-size:10px">{{ error_list['helper2-rate'] }}</small>
							</CCol>
							<CCol lg="6">
								<CInput
								@focus="$event.target.select()"
                    			:class="{'has-error' : error_list['helper2-allowance']}"
									v-if="dataParams.helper_b_id"
				                  label="Helper 2 Allowance"
				                 	v-model="dataParams.helper_b_allowance" 
									 @keypress="isNumber($event)"
				                />
								<small v-if="error_list['helper2-allowance']" class="text-danger" style="font-size:10px">{{ error_list['helper2-allowance'] }}</small>
							</CCol>
							
					
					</CRow>
						
					</fieldset>	
					<fieldset class="sub-fieldset" v-if="user_details.company != null && user_details.company.company_type.includes('Trucking') && (dataParams.status == 'completed' || dataParams.status == 'delivered')">
						<legend>Expenses</legend>
						<!--IF STATUS IS COMPLETED-->
						<CRow class="ml-3 mr-3">
						
								<CCol lg="3">
											<CInput
											 @paste="onPaste($event, 'dataParams','billable','toll_fee')"
												@focus="$event.target.select()"
                    							:class="{'has-error' : error_list['toll-fee']}"
							                 	label="Toll Fees"
							                  	placeholder="0.00"
							                  	v-model="dataParams.billable.toll_fee" 
											   	@keypress="isNumber($event)"
							                />
											<small v-if="error_list['toll-fee']" class="text-danger" style="font-size:10px">{{ error_list['toll-fee'] }}</small>
								</CCol> 
										
							<CCol lg="3">
								<CInput
								@paste="onPaste($event, 'dataParams','billable','other_expenses')"
									@focus="$event.target.select()"
									label="Other Expenses"
									placeholder="0.00"
									v-model="dataParams.billable.other_expenses" 
									@keypress="isNumber($event)"
								/>
							</CCol> 
										
								<CCol lg="3">
									<CInput
									@paste="onPaste($event, 'dataParams','billable','extra_drop')"
										@focus="$event.target.select()"
										label="Extra drop"
										placeholder="0.00"
										v-model="dataParams.billable.extra_drop" 
										@keypress="isNumber($event)"
										/>
								</CCol>
								<CCol lg="3">
									<CInput
									@paste="onPaste($event, 'dataParams','billable','extra_helper')"
										@focus="$event.target.select()"
										label="Extra helper"
										placeholder="0.00"
										v-model="dataParams.billable.extra_helper" 
										@keypress="isNumber($event)"
										/>
								</CCol>
								
								<CCol lg="3">
										<CInput
										@paste="onPaste($event, 'dataParams','billable','parking_fee')"
											@focus="$event.target.select()"
                    						:class="{'has-error' : error_list['parking-fee']}"
							                label="Parking Fees"
							                placeholder="0.00"
							                v-model="dataParams.billable.parking_fee" 
											 @keypress="isNumber($event)"
							                />
											<small v-if="error_list['parking-fee']" class="text-danger" style="font-size:10px">{{ error_list['parking-fee'] }}</small>
								</CCol>
								<CCol lg="3">
									<CInput
									@paste="onPaste($event, 'dataParams','billable','demurrage_fee')"
									@focus="$event.target.select()"
										label="Demurrage Fee"
											placeholder="0.00"
										v-model="dataParams.billable.demurrage_fee" 
										@keypress="isNumber($event)"
										/>
								</CCol>
								<CCol lg="3">
									<CInput
									@paste="onPaste($event, 'dataParams','billable','toll_fee_non_billable')"
									@focus="$event.target.select()"
										label="Toll Fee - Non-Billable"
											placeholder="0.00"
										v-model="dataParams.billable.toll_fee_non_billable" 
										@keypress="isNumber($event)"
										/>
								</CCol>
								<CCol lg="3">
									<CInput
									@paste="onPaste($event, 'dataParams','billable','backload_fee')"
									@focus="$event.target.select()"
										label="Backload Fee"
											placeholder="0.00"
										v-model="dataParams.billable.backload_fee" 
										@keypress="isNumber($event)"
										/>
								</CCol> 
			
						</CRow> 
					</fieldset>
					
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../style.css';
import Datetime from 'vuejs-datetimepicker'; 
import moment from 'moment';
import Loader from '../../loader.mixin.js';
import AssignedPersonnelTemplate from './assigned_personnel.vue';
import 'regenerator-runtime/runtime';
import _ from 'lodash'


export default {
	async mounted(){  
		
		 this.getCustomer();
		 this.getBookingType();
		 this.getBookingItemType();
		 this.getDepot();
		 this.getCommodityType();
		 this.getOrigin();
		 this.getVehicle()
		 this.getVehicleType();
		
		 const is_rebook = this.$route.params.rebook == '1';

		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(is_rebook) {
			this.editMode = false;
		}

		if(this.$route.params.id){
			this.$showLoading(true)
			await this.getHelperList();
			await this.getDriverList();
			await this.getAgency();

			if(is_rebook)
				await this.getDataRebook()
			else
				await this.getData();
				
			this.$showLoading(false)
		}
		else {
			this.getHelperList();
			this.getDriverList();
			this.getAgency();
		}

 		
		
		this.getVendor();
		
		
		this.getDestination();
		
		setTimeout(()=>{
			document.querySelector("#vehicle_no .vs__clear").onclick = ()=>{
				this.dataParams.vehicle_type_id = ""
			};
		})

		const fileArea1 = document.getElementById('file-area1')
		const fileArea2 = document.getElementById('file-area2')
		const fileArea3 = document.getElementById('file-area3')

		const inputFile1 = document.getElementById('input-file1')
		const inputFile2 = document.getElementById('input-file2')
		const inputFile3 = document.getElementById('input-file3')

		const imageView1 = document.getElementById('img-view1')
		const imageView2 = document.getElementById('img-view2')
		const imageView3 = document.getElementById('img-view3')

		const defaultView = document.getElementById('default-img-view');
		let vm = this;
		

		inputFile1.addEventListener("change", function(event) {
			if (config.fileSize(inputFile1.files[0].size) > 5) {
				
                    Swal.fire({
                        title: 'Error!',
                        text: 'File size must not be greater than 5MB',
                        icon: 'error',
                    })
                    // const f = document.querySelector('.form-control-file');
					const f = document.getElementById('input-file1')
                    f.value = '' }
					else {
				let link = URL.createObjectURL(inputFile1.files[0]);
				imageView1.style.backgroundImage = `url(${link})`;
				imageView1.textContent = '';
				vm.$set(vm.image_list,0,inputFile1.files[0])
			}
		});
		

		inputFile2.addEventListener("change", function() {
			if (config.fileSize(inputFile2.files[0].size) > 5) {
				
				Swal.fire({
					title: 'Error!',
					text: 'File size must not be greater than 5MB',
					icon: 'error',
				})
				// const f = document.querySelector('.form-control-file');
				const f = document.getElementById('input-file2')
				f.value = ''
		
			} 
			else {
				let link = URL.createObjectURL(inputFile2.files[0]);
				imageView2.style.backgroundImage = `url(${link})`;
				imageView2.textContent = '';
				vm.$set(vm.image_list,1,inputFile2.files[0])
			}
		});
		
		inputFile3.addEventListener("change", function() {
			if (config.fileSize(inputFile3.files[0].size) > 5) {
				
                    Swal.fire({
                        title: 'Error!',
                        text: 'File size must not be greater than 5MB',
                        icon: 'error',
                    })
                    // const f = document.querySelector('.form-control-file');
					const f = document.getElementById('input-file3')
                    f.value = ''
			} else {
				let link = URL.createObjectURL(inputFile3.files[0]);
				imageView3.style.backgroundImage = `url(${link})`;
				imageView3.textContent = '';
				vm.$set(vm.image_list,2,inputFile3.files[0])
			}
		});

	},
	data(){
		return{
			user_details: JSON.parse(window.localStorage.getItem('user_data')),
			company_type: JSON.parse(window.localStorage.getItem('user_data')).company.company_type,
			window,
			JSON,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "", 
			dataParams:{
				route_code: "",
				customer_id: "",
				booking_type_id: "",
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				commodity_id: "",
				vehicle_type_id: "",
				vendor_id: "",
				origin_id: "",
				agency_id: "",
				agency : {},
				// client_billing_id: "",
				destination_id: null,
				vehicle_id: null,
				driver_id: "",
				driver_label : null,
				helper_a_id: null,
				helper_a_label : null,
				helper_b_id: null,
				helper_b_label : null,
				furnishing_id: null,
				equipment_id: null,
				// users_id: "",
				asset_type: "vehicle",
				assigned_personnel : 'direct_hire',
				delivery_date: null,
				delivery_date_f: null,
				remarks: "",
				client_ref_no: "",
				other_ref_no: "",
				fuel_po: "",
				fuel_amount: "",
				// booking_no: "",
				// dispatch_datetime: "",
				// delivery_datetime: "",
				// received_datetime: "",
				// completed_datetime: "",
				client_rate: 0,
				no_of_trips: 1,
				// total_amount: "",
				// subcon_rate: "",
				driver_rate: "",
				driver_allowance: "",
				helper_a_allowance: "",
				helper_b_allowance: "",
				helper_a_rate: "",
				helper_b_rate: "",
				is_subcon: false,
				description: "",
				status: "",
				billable: {
					parking_fee: "",
					toll_fee: "",
					other_expenses: "",
					extra_drop: "",
					extra_helper: "",
				},
				remarks_b: "",
                assigned_personnel_helper_1 : 'direct_hire',
                assigned_personnel_helper_2 : 'direct_hire',
                agency_id_helper1 : null,
                agency_id_helper2 : null,
				driver_included1 : null,
				driver_included2 : null,
				fuel : "",
				destination_label : null,
				driver: {},
				helper_a : {},
				helper_b : {},
				helper1agency: {},
				helper2agency: {},
				booking_item_details: [
					{
						item_type: "",
						description: "",
						length: "",
						width: "",
						height: "",
						weight: ""
					}
				],
				no_of_drops: null
			},
			vehicle_id: "",
			vehicle_type_id: "",
			editMode: false,
			selectedRow: {},
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			
			
			isLoading: false,
			uploadPercentage: 0,
			config,
			customerList: {
				data: []
			},
			bookingTypeList: {
				data: []
			},
			bookingItemTypeList: {
				data: []
			},
			commodityTypeList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			destinationList: {
				data: []
			},
			vehicleList: {
				data: []
			},
			agencyList: {
				data: []
			},
			agencyListSubcon: {
				data: []
			},
			driverList: {
				data: []
			},
			driverListHolder: {
				data: []
			},
			helperList: {
				data: []
			},
            helperList2: {
				data: []
			},
			helperListHolder: {
				data: []
			},
            agencyListHolder: {
                data: []
            },
			equipmentList: {
				data: []
			},
			furnishingList: {
				data: []
			},
			equipmentDetails: {},
			furnishingDetails: {},
			error_list : {},
			dataParamsHolder: {},

			outsource_driver: {},
			outsource_helper1: {},
			outsource_helper2: {},
			final_helper_list1 : {
				data: []
			},
			final_helper_list2 : {
				data: []
			},
			all_personnel: [],
			computing_client_rate: false,
			// image: 'https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg'
			image: '@/assets/IMAGES/upload-image.png',
			image_list: [
				null,
				null,
				null
			],
			uploadPercentage: 0,
			msg: 'Loading ...',
			suggested_rate: 0,
			cancelTokenSource: null,
			options: []
		}
	},
	computed: {
        assigned_personnel_title() {
            return (this.dataParams.assigned_personnel == 'outsourced' && this.dataParams.is_subcon) ? 'Personnel Subcon' : 'Personnel Agency'
        },
        driver_title() {

            return (this.dataParams.assigned_personnel == 'outsourced') ? 'Outsourced-Driver' : 'Driver'
        },
        helper1_title() {
            return (this.dataParams.assigned_personnel_helper_1 == 'outsourced') ? 'Outsourced-Helper 1' : 'Helper 1'
        },
        helper2_title() {
            return (this.dataParams.assigned_personnel_helper_2 == 'outsourced') ? 'Outsourced-Helper 2' : 'Helper 2'
        },
        hasError() {
            return Object.keys(this.error_list).length;
        },
		selected_driver() {
			return this.dataParams.driver?.name ?? null
		},
		selected_helper_a() {
			return this.dataParams.helper_a?.name ?? null
		},
		selected_helper_b() {
			return this.dataParams.helper_b?.name ?? null
		},
		selected_driver_agency() {
			return this.dataParams.agency?.vendor_name ?? null
		},
		selected_helper_a_agency() {
			return this.dataParams.helper1agency?.vendor_name ?? null
		},
		selected_helper_b_agency() {
			return this.dataParams.helper2agency?.vendor_name ?? null
		},
		total_weight() {
			let total_weight = 0;
			if(this.dataParams.booking_item_details?.length) {
				this.dataParams.booking_item_details.forEach(item => {
					if(item?.weight && !isNaN(item.weight)) {
						total_weight += item.weight;
					}
				})
			}

			return parseFloat(total_weight);
		}
    },
	mixins: [Loader],
	name: 'Tables',
	components: {Search, Datepicker, vSelect, Datetime, AssignedPersonnelTemplate},
	methods: {
		useSuggestedCr() {
			this.$set(this.dataParams,'client_rate', this.suggested_rate)
		},
		uploadImages(booking_id) {
			try {
				
				if(!this.image_list.some(f => f != null)) return;

				var formData = new FormData();
				formData.append('booking_id', booking_id);
				let indx = 0;
				for (let index = 0; index < this.image_list.length; index++) {
					const file = this.image_list[index];
					if(file) {
						formData.append(`booking_images[${indx}]`, file);
						indx++;
					}
				}

				return axios.post(config.api_path + "/booking/upload-image", formData, {
						onUploadProgress: (prog) => {
							var uploadPercentage = parseInt(
							Math.round((prog.loaded / prog.total) * 100)
							);
							this.uploadPercentage = uploadPercentage;
						},
					})
				} catch (error) {
					console.error(error)
				}
		},
		deleteImage(index) {
			let vm = this;
			
			vm.$set(vm.image_list,index,null)
			const defaultView = document.getElementById('default-img-view');
			const imgView = document.getElementById(`img-view${index + 1}`)
			imgView.style.backgroundImage = null;
			imgView.innerHTML = defaultView.innerHTML;

			// this.reArrangeImage()
		},
		deleteAll() {
			this.dataParams.booking_item_details = [{
				item_type: "",
				description: "",
				length: "",
				width: "units",
				height: "",
				weight: ""
			}]
		},
		deleteItem(index) {
			this.dataParams.booking_item_details.splice(index, 1)
		},
		addItem(){
			const has_blank = this.dataParams.booking_item_details.some(item => !item.item_type || !item.weight)
			if(has_blank)
				Swal.fire('Opps','Butangi og value ang item type og weight')
			else
			this.dataParams.booking_item_details.push({
				item_type: "",
				description: "",
				length: "",
				width: "",
				height: "",
				weight: ""
			})
		},
		driverIncluded(type) {
			console.log(type, this.dataParams.driver_included1, this.dataParams.assigned_personnel_helper_1)
			let driverList = {...this.driverListHolder};
			let val = '';
			switch(type) {
				case 1:
					// get drivers
					val = this.dataParams.assigned_personnel_helper_1;
					driverList.data = driverList.data.filter((value, index)=>{
						if(!this.dataParams.is_subcon) {
							if(val == 'outsourced')
							return value?.vendor?.vendor_type_name?.setting_name?.toLowerCase() == "manpower" && value.employment_type == val;
							else return value.employment_type == val
						}

						return value.employment_type == val;
					})
					// end

					this.dataParams.helper_a_id = null;
					if(this.dataParams.driver_included1) {
						this.final_helper_list1.data = [...this.helperList.data];
						this.helperList.data = [...driverList.data, ...this.helperList.data]
					}
					else{
						this.helperList.data = [...this.final_helper_list1.data]
					}

					break;
				case 2:
					// get drivers
					val = this.dataParams.assigned_personnel_helper_2;
					driverList.data = driverList.data.filter((value, index)=>{
						if(!this.dataParams.is_subcon) {
							if(val == 'outsourced')
							return value?.vendor?.vendor_type_name?.setting_name?.toLowerCase() == "manpower" && value.employment_type == val;
							else return value.employment_type == val
						}

						return value.employment_type == val;
					})
					// end

					this.dataParams.helper_b_id = null;
					if(this.dataParams.driver_included2) {
						this.final_helper_list2.data = [...this.helperList.data];
						this.helperList2.data = [...driverList.data, ...this.helperList2.data]
					}
					else {
						
						this.helperList2.data = [...this.final_helper_list2.data]
					}
					break;
			}
			
		},
		
		onPaste(evt, parent, object, key) {
				let prev = evt.target.value;
				let value = evt.clipboardData.getData('text');
				
				let clean = value?.replace(/,/g, "") ?? '';

				if(isNaN(clean)) {
					alert('Invalid number value.')
					evt.preventDefault();
					evt.target.value = prev;
					if(object) {
						this[parent][object][key] = prev;
					}
					else this[parent][key] = prev;
					return true;
				}
				evt.preventDefault()
				evt.target.value = clean;
				if(object)
					this.$set(this[parent][object], key, clean)
				else this.$set(this[parent], key, clean)

				console.log(this[parent][object][key])
				return true;
				
			},
        personnelTypeChanged(val, type) {
			let that=this;
			console.log('TYPE', type, val)
            if(type === 'helper1') {
				console.log()
				this.outsource_helper1 = {};
                this.helperList = {...this.helperListHolder};
                
                this.helperList.data = this.helperList.data.filter((value, index)=>{
                    if(!that.dataParams.is_subcon) {
                        if(val == 'outsourced')
                        return value?.vendor?.vendor_type_name?.setting_name?.toLowerCase() == "manpower" && value.employment_type?.toLowerCase() == val;
                        else return value.employment_type == val
                    }
                    return value.employment_type == val;
                })

				this.outsource_helper1 = {...this.helperList}

            }
            else if(type === 'helper2') {
				this.outsource_helper2 = {};
                this.helperList2 = {...this.helperListHolder};

				// this.dataParams.helper_b={}
                
                this.helperList2.data = this.helperList2.data.filter((value, index)=>{
                    if(!that.dataParams.is_subcon) {
                        if(val == 'outsourced')
                        return value?.vendor?.vendor_type_name?.setting_name?.toLowerCase() == "manpower" && value.employment_type?.toLowerCase() == val;
                        else return value.employment_type == val
                    }
                    return value.employment_type == val;
                })

				this.outsource_helper2 = {...this.helperList2}
            }
            else {
				console.log('subcon', this.dataParams.is_s)
				this.outsource_driver = {};
                this.driverList = {...this.driverListHolder};
                this.driverList.data = this.driverList.data.filter((value, index)=>{
					if(!that.dataParams.is_subcon) {
                        if(val == 'outsourced')
                        return value?.vendor?.vendor_type_name?.setting_name?.toLowerCase() == "manpower" && value.employment_type?.toLowerCase() == val;
                        else return value.employment_type == val
                    }

                    return value.employment_type == val;
                })

				this.outsource_driver = {...this.driverList};
				console.log(this.driverList)
            }
            
        },
		setPersonnelTypeDriver(val) {
			
			if(this.dataParams.assigned_personnel != val) {
				this.dataParams.driver = {}
				this.dataParams.driver_id = null;
				this.dataParams.agency = {};
				this.dataParams.agency_id = null;
			}
			this.dataParams.assigned_personnel = val;
			
			this.personnelTypeChanged(val, 'driver')
        },
        setPersonnelTypeHelper1(val) {
			
			if(this.dataParams.assigned_personnel_helper_1 != val) {
				this.dataParams.helper_a = {}
				this.dataParams.helper_a_id = null;
				this.dataParams.helper1agency = {};
				this.dataParams.agency_id_helper1 = null;
			}
			
			this.$set(this.dataParams,'assigned_personnel_helper_1', val)
			
			this.personnelTypeChanged(val, 'helper1')
			this.dataParams.driver_included1 = null;
			
			
        },
        setPersonnelTypeHelper2(val) {
			
			if(this.dataParams.assigned_personnel_helper_2 != val) {
				this.dataParams.helper_b = {}
				this.dataParams.helper_b_id = null;
				this.dataParams.helper2agency = {};
				
				this.dataParams.agency_id_helper2 = null;
			}
            this.$set(this.dataParams,'assigned_personnel_helper_2', val)
			
			this.personnelTypeChanged(val, 'helper2')
			this.dataParams.driver_included2 = null;
        },
        
		subconChanged() {
			const val = this.dataParams.is_subcon;
			
			const DIRECT_HIRE = 'direct_hire', OUTSOURCED = 'outsourced', MANPOWER = 'manpower';
			this.driverList = {...this.driverListHolder};
			this.helperList = {...this.helperListHolder};
			this.helperList2 = {...this.helperListHolder};
			this.agencyList = {...this.agencyListHolder};

			console.log(this.helperList.data,"ASDAD")

			this.dataParams.agency = {}
			this.dataParams.driver = {}
			this.dataParams.helper1agency ={}
			this.dataParams.helper_a={}
			this.dataParams.helper2agency ={}
			this.dataParams.helper_b={}
			this.dataParams.vehicle_info={}

			this.dataParams.agency_id = null;
			this.dataParams.agency_id_helper1 = null;
			this.dataParams.agency_id_helper2 = null;
			this.dataParams.driver_id = null;
			this.dataParams.helper_a_id = null;
			this.dataParams.helper_b_id = null;
			this.dataParams.vehicle_id = null;
			this.dataParams.driver_included1 = null;
			this.dataParams.driver_included2 = null;
			this.dataParams.vendor_id = null;
			this.dataParams.vehicle_type_id = null;
			

			
			 if (val){
				this.driverList.data = this.driverList.data.filter(value => value.employment_type == this.dataParams.assigned_personnel)
				this.helperList.data = this.helperList.data.filter(value => value.employment_type == this.dataParams.assigned_personnel_helper_1)
				this.helperList2.data = this.helperList2.data.filter(value => value.employment_type == this.dataParams.assigned_personnel_helper_2)
			 }
			 else {

                this.agencyList.data = this.agencyList.data.filter(val => val.vendor_type_name.setting_name.toLowerCase() == MANPOWER)

				if(this.dataParams.assigned_personnel == DIRECT_HIRE)
					this.driverList.data = this.driverList.data.filter(value => value.employment_type == DIRECT_HIRE)
				else
					this.driverList.data = this.driverList.data.filter(value => value.employment_type == OUTSOURCED  && value.vendor.vendor_type_name.setting_name.toLowerCase() == MANPOWER)
				
				if(this.dataParams.assigned_personnel_helper_1 == DIRECT_HIRE)
					this.helperList.data = this.helperList.data.filter(value => value.employment_type == DIRECT_HIRE)
				else 
					this.helperList.data = this.helperList.data.filter(value => value.employment_type == OUTSOURCED  && value.vendor.vendor_type_name.setting_name.toLowerCase() == MANPOWER)
				
				if(this.dataParams.assigned_personnel_helper_2 == DIRECT_HIRE)
					this.helperList2.data = this.helperList2.data.filter(value => value.employment_type == DIRECT_HIRE)
				else 
					this.helperList.data = this.helperList.data.filter(value => value.employment_type == OUTSOURCED  && value.vendor.vendor_type_name.setting_name.toLowerCase() == MANPOWER)

			 }

			 this.getVehicle()
		},
	    submit(){
			
	    	var ax = {};
	    	if(this.dataParams.driver_rate == ""){
	    		this.dataParams.driver_rate = null;
	    	}
	    	if(this.dataParams.helper_a_rate == ""){
	    		this.dataParams.helper_a_rate = null;
	    	}
	    	if(this.dataParams.helper_b_rate == ""){
	    		this.dataParams.helper_b_rate = null;
	    	}
	    	if(this.dataParams.destination_id == ""){
	    		this.dataParams.destination_id = null;
	    	}
	    	if(this.dataParams.vendor_id == ""){
	    		this.dataParams.vendor_id = null;
	    	}
	    	if(this.dataParams.agency_id == ""){
	    		this.dataParams.agency_id = null;
	    	}
	    	if(this.dataParams.vehicle_type_id == ""){
	    		this.dataParams.vehicle_type_id = null;
	    	}
	    	if(this.dataParams.equipment_id == ""){
	    		this.dataParams.equipment_id = null;
	    	}
	    	if(this.dataParams.driver_id == ""){
	    		this.dataParams.driver_id = null;
	    	}
	    	if(this.dataParams.furnishing_id == ""){
	    		this.dataParams.furnishing_id = null;
	    	}
	    	if(this.dataParams.helper_b_id == ""){
	    		this.dataParams.helper_b_id = null;
	    	}
	    	if(this.dataParams.helper_a_id == ""){
	    		this.dataParams.helper_a_id = null;
	    	} 
	    	if(this.dataParams.billable.toll_fee == ""){
	    		this.dataParams.billable.toll_fee = 0;
	    	} 
	    	if(this.dataParams.billable.parking_fee == ""){
	    		this.dataParams.billable.parking_fee = 0;
	    	}
	    	if(this.dataParams.billable.other_expenses == ""){
	    		this.dataParams.billable.other_expenses = 0;
	    	}
	    	if(this.dataParams.driver_rate == ""){
	    		this.dataParams.driver_rate = null;
	    	}
	    	if(this.dataParams.fuel_amount == ""){
	    		this.dataParams.fuel_amount = null;
	    	} 
	    	
	    	if(!this.dataParams.billable.extra_drop){
	    		this.dataParams.billable.extra_drop = 0;
	    	} 
	    	if(!this.dataParams.billable.extra_helper){
	    		this.dataParams.billable.extra_helper = 0;
	    	} 
			
			this.dataParams.total_amount = this.dataParams.client_rate * this.dataParams.no_of_trips

			// modify to avoid triggering audit log
			this.dataParams.is_subcon = this.dataParams.is_subcon ? 1 : 0;
			this.dataParams.client_rate = this.dataParams?.client_rate ? this.dataParams?.client_rate : "0.00";
			this.dataParams.total_amount = this.dataParams?.total_amount ? this.dataParams?.total_amount : "0.00";
			// end

	    	this.dataParams.user_id = config.getUserDetail('id');
	    	
	    	this.dataParams.delivery_date =  moment(this.dataParams.delivery_date_f).startOf('day').unix();
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/booking/"+this.$route.params.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/booking", this.dataParams)
	    	}

			this.isLoading = true;
	    	ax.then(async (response) => {
				const {status, message, data} = response.data;
				

				if(status == 'error') {
					Swal.fire({
						title: 'Saving failed!',
						text: message,
						icon: 'error',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						confirmButtonText: 'Ok'
						})
					return;
				}

				if(this.image_list.some(f => f != null)) {
					this.msg = 'Uploading images ...';
					await this.uploadImages(data.id)
					this.msg = 'Loading ...';
				}
				
	    		var text = 'Booking successfully added!';
	    		if(this.editMode){
	    			text = 'Booking successfully updated!';
	    		}
	    		// Swal.fire({
				// 	title: 'Success!',
				// 	text,
				// 	icon: 'success', 
				// })
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
	    			route_code: "",
					customer_id: "",
					booking_type_id: "",
					depot_id: "",
					commodity_id: "",
					vehicle_type_id: "",
					// vendor_id: "",
					agency_id: "",
					origin_id: "",
					// client_billing_id: "",
					destination_id: "",
					vehicle_id: null,
					driver_id: "",
					helper_a_id: null,
					helper_b_id: null,
					furnishing_id: null,
					equipment_id: null,
					// users_id: "",
					asset_type: "vehicle",
					delivery_date: "",
					remarks: "",
					client_ref_no: "",
					other_ref_no: "",
					fuel_po: "",
					fuel_amount: "",
					// booking_no: "",
					// dispatch_datetime: "",
					// delivery_datetime: "",
					// received_datetime: "",
					// completed_datetime: "",
					// client_rate: "",
					no_of_trips: 0,
					// total_amount: "",
					// subcon_rate: "",
					driver_rate: "",
					driver_allowance: "",
					helper_a_allowance: "",
					helper_b_allowance: "",
					helper_a_rate: "",
					helper_b_rate: "",
					is_subcon: false,
					description: "",					
					billable: {
						parking_fee: "",
						toll_fee: "",
						other_expenses: "",
						extra_drop: "",
						extra_helper: "",
					},
					remarks_b: "",
					fuel: 0,
					no_of_drops: "",
					booking_item_details: []
				}

				this.isLoading = false;
				Swal.fire({
					title: 'Success',
					text: text,
					icon: 'success',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Ok'
					}).then((result) => {
					if (result.isConfirmed) {
						this.$router.push('/main/view_booking/'+response.data.data.id)
					}
				})
	    		
	    	}).catch(err => {
				this.isLoading = false;
			})
	    },

	    getVendor(){

	    	axios.get(config.api_path+'/vendor?vendor_type_label=trucker_and_trucking_services&page=1&limit=10000000')
	    	.then(response => {
	    		this.vendorList = response.data;
				
				
	    		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		});

				
	    	})

	    },
		

	    getAgency(){

	    	// axios.get(config.api_path+'/vendor?vendor_type_label=manpower&page=1&limit=10000000')
            return axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    	.then(response => {
	    		this.agencyList = response.data;

				this.agencyList.data = this.agencyList.data.filter(val => {
					return val.status == 'active'
				})

                this.agencyListHolder = {...this.agencyList};

	    		this.agencyList.data = this.agencyList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		})

				
	    	})

	    },

	    getEquipment(){

	    	axios.get(config.api_path+'/equipment?page=1&limit=10000000')
	    	.then(response => {
	    		this.equipmentList = response.data;
	    		this.equipmentList.data = this.equipmentList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.equipment_prep_no;
	    			return value;
	    		})
	    	})

	    },

	    getFurnishing(){

	    	axios.get(config.api_path+'/furnishing?page=1&limit=10000000')
	    	.then(response => {
	    		this.furnishingList = response.data;
	    		this.furnishingList.data = this.furnishingList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.asset_no;
	    			return value;
	    		})
	    	})

	    },

	    getVehicleType(){
			this.isLoading = true;
	    	axios.get(config.api_path+'/setting?setting_type=vehicle_type&page=1&limit=10000000')
	    	.then(response => {
	    		this.vehicleTypeList = response.data; 
	    	})
			.finally(fin => {
				this.isLoading = false;
			})

	    },
		

	    getMakerModel(){

	    	axios.get(config.api_path+'/setting?setting_type=maker_model&page=1&limit=10000000')
	    	.then(response => {
	    		this.makerModelList = response.data; 
	    	})

	    },

		  getDepot(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
       		   })
	    	.then(response => {
	    		this.depotList = response.data; 
	    	})

	    },

	    

	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    },
		
	    getCustomer(){

	    	axios.get(config.api_path+'/reference/customer-list',{
				params:{
					show_all : true
				}
			})
	    	.then(response => {
	    		this.customerList = response.data;
	    		// this.customerList.data = this.customerList.data.filter((value, index)=>{
	    		// 	return value.status == 'active';
	    		// }) 
	    	})

	    },
		getBookingType(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'booking_type'
            }
       		   })
	    	.then(response => {
	    		this.bookingTypeList = response.data; 
	    	})

	    },
		getBookingItemType(){

		axios.get(config.api_path+'/reference/settings-list',{
			params:{
			setting_type:'booking_item_type'
			}
				})
			.then(response => {
				this.bookingItemTypeList = response.data; 
			})

		},

		 getCommodityType(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'commodity_type'
            }
       		   })
	    	.then(response => {
	    		this.commodityTypeList = response.data; 
	    	})

	    },

	   
		 backToTable(){
	    	this.$router.push('/main/booking')
	    },
		
		 getOrigin(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'origin'
            }
       		   })
	    	.then(response => {
	    		this.originList = response.data; 
	    	})

	    },
		onSearch(search, loading) {
				
			if(search.length > 2) {
				let vm = this;
				loading(true);
				this.search(loading, search, this);
			}
		},
		search: _.debounce((loading, search, vm) => {
			const params = {
				commodity_id: vm.dataParams.commodity_id,
				depot_id: vm.dataParams.depot_id,
				vehicle_type_id: vm.dataParams.vehicle_type_id,
				customer_id: vm.dataParams.customer_id,
				origin_id : vm.dataParams.origin_id,
				qry: search
			}

			if(vm.cancelTokenSource) {
				vm.cancelTokenSource.cancel();
				vm.cancelTokenSource = null;
			}

			vm.cancelTokenSource = axios.CancelToken.source();

			if(vm.company_type.toLowerCase() == "trucking with agency"){      
				axios.get(`${config.api_path}/reference/furthest-destination`, {
					cancelToken: vm.cancelTokenSource.token,
					params
				}).then(response => {
					const d = response.data;
					vm.options = d.data.map((value, index) => {
						value.setting_name = value.destination.setting_name;
						return value
					}); 

					loading(false);
				}).catch(err => {
					console.error('DESTINATION SEARCH - ',err)
					loading(false);
				})
			}
			else {
				axios.get(config.api_path+'/reference/settings-list',{
						params: {
							setting_type:'destination',
							qry: search
						}
					})
					.then(response => {
						const d = response.data;
						vm.options = d.data.map((value, index) => {
							value.destination_id = value.id;
							value.destination = value.setting_name
							return value;
						}); 

						loading(false);
					}).catch(err => {
						loading(false);
						console.error(err)
					})
			}

		}, 350),

	   
		   getDestination(){
			return; // method not used
				console.log(this.dataParams, 'GET DESTINATION')
				
				let customer_id = this.dataParams.customer_id;
				
		    	if(this.company_type.toLowerCase() == "trucking with agency"){
					if(!customer_id) return;
					axios.get(config.api_path+'/client_rate?commodity_id='+this.dataParams.commodity_id
					+'&depot_id='+this.dataParams.depot_id
					+'&vehicle_type_id='+this.dataParams.vehicle_type_id
					+'&customer_id='+customer_id
					+'&origin_id='+this.dataParams.origin_id
					+'&page=1&limit=1000000')
					.then(response => {
						this.destinationList = response.data;
						this.destinationList.data = this.destinationList.data.map((value, index) => {
							value.setting_name = value.destination.setting_name;
							return value
						}); 
						}).catch(err => {
						console.error(err)
					})
				}
				else{
					// axios.get(config.api_path+'/reference/settings-list',{
          			//   params:{
					// 	setting_type:'destination'
					// 	}
					// 	})
					// 	.then(response => {
					// 		this.destinationList = response.data; 
					// 	})
					// }
					// },
					// axios.get(config.api_path+'/setting?setting_type=destination&page=1&limit=1000000')
			    	// .then(response => {
			    	// 	this.destinationList = response.data; 
			    	// 	this.destinationList.data = this.destinationList.data.map((value, index) => {
			    	// 		value.destination_id = value.id
			    	// 		return value;
			    	// 	})
			    	// })
					axios.get(config.api_path+'/reference/settings-list',{
						params:{
						setting_type:'destination'
						}
							})
						.then(response => {
							this.destinationList = response.data; 
							this.destinationList.data = this.destinationList.data.map((value, index) => {
			    			value.destination_id = value.id
			    			return value;
						}); 
							
						})
					}
				},
			// 	}

		    // },


	    getVehicle(){
	    	var vendor_id = this.dataParams.vendor_id;
			//Subcon =1 -> not company owned (0)
			//Subcon = 0 -> company owned (1)
			axios.get(config.api_path+'/reference/vehicle-no',{
				params: {
					is_subcon : this.dataParams.is_subcon ? 1 : 0,
					vendor_id
				}
			}).then(res => {
				const {data , status} = res.data;
				if(status == 'success') {
					this.vehicleList.data = data;
				}
			})

			return;
	    	if(this.dataParams.is_subcon){
	    		axios.get(config.api_path+'/vehicle?company_owned=0&status=active&vendor_type=trucker&vendor_id='+vendor_id+'&page=1&limit=10000000')
			.then(response => {
		    		this.vehicleList = response.data; 
		    		this.vehicleList.data = this.vehicleList.data.filter((value, index)=>{
		    			if(!this.dataParams.is_subcon){
		    				return value.company_owned == 1;
		    			}
		    			else{
		    				return value.company_owned != 1;
		    			}
		    		})
		    	})
	    	}
	    	else{
				this.dataParams.vendor_id = null;
	    		axios.get(config.api_path+'/vehicle?company_owned=1&status=active&vendor_type=trucker&vendor_id='+vendor_id+'&page=1&limit=10000000')
		    	.then(response => {
		    		this.vehicleList = response.data; 
		    		this.vehicleList.data = this.vehicleList.data.filter((value, index)=>{
		    			if(!this.dataParams.is_subcon){
		    				return value.company_owned == 1;
		    			}
		    			else{
		    				return value.company_owned != 1;
		    			}
		    		})
		    	})
	    	}

	    },

	    getDriverList(with_agency){
			
	    	return axios.get(config.api_path+'/personnel?status=active&personnel_type_label=Driver&page=1&limit=10000000'+(with_agency ? "&vendor_id="+this.dataParams.agency_id : ""))
	    	.then(response => {
	    		this.driverList = response.data; 
				
				if(!this.driverListHolder.data.length)
					this.driverListHolder = {...this.driverList};
				else if(this.driverListHolder.data.length && !this.dataParams.agency_id) 
					this.driverList = {...this.driverListHolder};
				
				this.driverList.data = this.driverList.data.filter(value => value.employment_type == this.dataParams.assigned_personnel)


				this.dataParams.driver_included1 = this.isHelperDriver(this.dataParams.helper_a_id)
				this.dataParams.driver_included2 = this.isHelperDriver(this.dataParams.helper_b_id)

				console.log('DRIVER', this.dataParams.driver_included1,this.dataParams.driver_included2)

				// console.log(this.dataParams.driver_included1,'in')

	    	})

	    },
 		getHelperList(){

	    	return axios.get(config.api_path+'/personnel?status=active&personnel_type_label=helper&page=1&limit=10000000')
	    	.then(response => {
	    		this.helperList = response.data; 
                this.helperList2 = {...this.helperList};
				this.helperListHolder = {...this.helperList};
				
				this.helperList.data = this.helperList.data.filter(value => value.employment_type == this.dataParams.assigned_personnel_helper_1)
				this.helperList2.data = this.helperList2.data.filter(value => value.employment_type == this.dataParams.assigned_personnel_helper_2)


				if(this.dataParams.driver_included1) {
					this.final_helper_list1.data = [...this.helperList.data];
					this.helperList.data = [...this.driverList.data, ...this.helperList.data];
				}
				if(this.dataParams.driver_included2) {
					this.final_helper_list2.data = [...this.helperList2.data];
					this.helperList2.data = [...this.driverList.data, ...this.helperList2.data];
				}
	    	})

	    },
		getDataRebook() {
			const exclude_key = [
				'client_rate',
				'subcon_rate',
				'driver_rate',
				'driver_allowance',
				'helper_a_rate',
				'helper_b_rate',
				'helper_a_allowance',
				'helper_b_allowance',
				'billable',
				'destination',
				'destination_id',
				'completed_datetime',
				'delivered_odometer_id',
				'delivery_datetime',
				'dispatched_by_id',
				'dispatched_odometer_id',
				'received_datetime',
				'trucker_settlement_id',
				'client_billing_id',
				'status',
			];

			return axios.get(config.api_path+"/booking/"+this.$route.params.id)
			.then(response=>{
				const {data} = response.data;  
				
				exclude_key.forEach(key => {
					delete data[key];
				})

				data['billable'] = {
					parking_fee: "",
					toll_fee: "",
					other_expenses: "",
					extra_drop: "",
					extra_helper: "",
				}
				
				this.dataParams = {...data};

				if(this.dataParams.is_subcon == 0){
					this.dataParams.is_subcon = false
				}
				if(this.dataParams.is_subcon == 1){
					this.dataParams.is_subcon = true
				}
				if(this.dataParams.billable === null){
					this.dataParams.billable = {
						parking_fee: "",
						toll_fee: "",
						other_expenses: "",
					}
				}
				
				this.dataParams.delivery_date_f = moment().format("MM-DD-YYYY");
				this.dataParams.delivery_date = moment().format("MM-DD-YYYY");
				
				this.dataParams.booking_history = this.dataParams.booking_history.map((value, index)=>{
					value.users_id = "N/A";
					if(value.remarks == null || value.remarks == ""){
						value.remarks = "N/A"
					}
					value.created_at =  moment(value.created_at).format('MMM DD, YYYY hh:mm A') 
					return value;
				})

				this.dataParamsHolder = {...this.dataParams};

				this.dataParams.agency_id_helper1 = this.dataParams.agency_id_helper1 ? parseInt(this.dataParams.agency_id_helper1) : this.dataParams.agency_id_helper1;
				this.dataParams.agency_id_helper2 = this.dataParams.agency_id_helper2 ? parseInt(this.dataParams.agency_id_helper2) : this.dataParams.agency_id_helper2;
				// this.dataParams.destination_label = this.dataParamsHolder.destination?.setting_name ?? null;

				
				setTimeout(() => {
					this.dataParams.driver_included1 = this.isHelperDriver(this.dataParams.helper_a_id)
					this.dataParams.driver_included2 = this.isHelperDriver(this.dataParams.helper_b_id)
					let driverList = {data:[]};
					if(this.dataParams.driver_included1) {
						driverList.data = [...this.driverListHolder.data.filter(value => value.employment_type.toLowerCase() == this.dataParams.assigned_personnel_helper_1.toLowerCase())];
						this.helperList = {...this.helperListHolder};
						this.helperList.data = this.helperList.data.filter(value => value.employment_type == this.dataParams.assigned_personnel_helper_1)
						this.final_helper_list1.data = [...this.helperList.data];
						this.helperList.data = [...driverList.data, ...this.helperList.data];

						this.helperList.data.forEach(element => {
							if(element.id == 1930) console.log(element.id)
						});
						this.driverList.data.forEach(element => {
							if(element.id == 1930) console.log(element.id, 'driver')
						});

						
					}
					if(this.dataParams.driver_included2) {
						driverList.data = [...this.driverListHolder.data.filter(value => value.employment_type.toLowerCase() == this.dataParams.assigned_personnel_helper_2.toLowerCase())];
						
						this.helperList2 = {...this.helperListHolder};
						this.helperList2.data = this.helperList2.data.filter(value => value.employment_type == this.dataParams.assigned_personnel_helper_2)
						this.final_helper_list2.data = [...this.helperList2.data];
						this.helperList2.data = [...driverList.data, ...this.helperList2.data];
					}

					this.agencyList.data = this.agencyList.data.filter(val => !this.dataParams.is_subcon ? val.vendor_type_name.setting_name.toLowerCase() === 'manpower' : true)
				}, 500);
		

				// this.$set(this.dataParams,'vehicle_type_id',value);

				
				this.getPersonnelRate();
				this.getVehicle();
				this.getVehicleType()
			}).catch(err => {
				this.$showLoading(false)
			})

		},
	    getData(){
			return axios.get(config.api_path+"/booking/"+this.$route.params.id)
			.then(response=>{
				this.dataParams = response.data.data;
				if(this.dataParams.is_subcon == 0){
					this.dataParams.is_subcon = false
				}
				if(this.dataParams.is_subcon == 1){
					this.dataParams.is_subcon = true
				}
				if(this.dataParams.billable === null){
					this.dataParams.billable = {
						parking_fee: "",
						toll_fee: "",
						other_expenses: "",
					}
				}
				
				this.dataParams.delivery_date_f = moment.unix(this.dataParams.delivery_date).format("MM-DD-YYYY");
				
				this.dataParams.booking_history = this.dataParams.booking_history.map((value, index)=>{
					value.users_id = "N/A";
					if(value.remarks == null || value.remarks == ""){
						value.remarks = "N/A"
					}
					value.created_at =  moment(value.created_at).format('MMM DD, YYYY hh:mm A') 
					return value;
				})

				this.dataParamsHolder = {...this.dataParams};

				if(this.$route.params.id && this.dataParams.status == "completed" && (parseInt(this.dataParams.client_rate) == 0 || this.dataParams.client_rate == null)){
					this.getClientRate(true, this.dataParams.vehicle_type_id);
					console.log(this.vehicleList.data, "VEHICKLE")
				}
				this.dataParams.agency_id_helper1 = this.dataParams.agency_id_helper1 ? parseInt(this.dataParams.agency_id_helper1) : this.dataParams.agency_id_helper1;
				this.dataParams.agency_id_helper2 = this.dataParams.agency_id_helper2 ? parseInt(this.dataParams.agency_id_helper2) : this.dataParams.agency_id_helper2;
				this.dataParams.destination_label = this.dataParamsHolder.destination?.setting_name ?? null;

				
				setTimeout(() => {
					this.dataParams.driver_included1 = this.isHelperDriver(this.dataParams.helper_a_id)
					this.dataParams.driver_included2 = this.isHelperDriver(this.dataParams.helper_b_id)
					let driverList = {data:[]};
					if(this.dataParams.driver_included1) {
						driverList.data = [...this.driverListHolder.data.filter(value => value.employment_type.toLowerCase() == this.dataParams.assigned_personnel_helper_1.toLowerCase())];
						this.helperList = {...this.helperListHolder};
						this.helperList.data = this.helperList.data.filter(value => value.employment_type == this.dataParams.assigned_personnel_helper_1)
						this.final_helper_list1.data = [...this.helperList.data];
						this.helperList.data = [...driverList.data, ...this.helperList.data];

						this.helperList.data.forEach(element => {
							if(element.id == 1930) console.log(element.id)
						});
						this.driverList.data.forEach(element => {
							if(element.id == 1930) console.log(element.id, 'driver')
						});

						
					}
					if(this.dataParams.driver_included2) {
						driverList.data = [...this.driverListHolder.data.filter(value => value.employment_type.toLowerCase() == this.dataParams.assigned_personnel_helper_2.toLowerCase())];
						
						this.helperList2 = {...this.helperListHolder};
						this.helperList2.data = this.helperList2.data.filter(value => value.employment_type == this.dataParams.assigned_personnel_helper_2)
						this.final_helper_list2.data = [...this.helperList2.data];
						this.helperList2.data = [...driverList.data, ...this.helperList2.data];
					}

					this.agencyList.data = this.agencyList.data.filter(val => !this.dataParams.is_subcon ? val.vendor_type_name.setting_name.toLowerCase() === 'manpower' : true)

					if(this.dataParams?.img_uri && this.dataParams?.img_uri?.length) {
						let counter = 0;
						this.dataParams.img_uri.forEach(element => {
							const imgView = document.getElementById(`img-view${counter+1}`)
							imgView.style.backgroundImage = `url(${element})`;
							imgView.textContent = '';
							this.$set(this.image_list,counter,element);

							counter++;
						});
					}
					
				}, 500);
		

				// this.$set(this.dataParams,'vehicle_type_id',value);

				
				this.getPersonnelRate();
				this.getVehicle();
				this.getVehicleType()
			}).catch(err => {
				this.$showLoading(false)
			})

		}, 
		isHelperDriver(id) {
			let hold = this.driverListHolder.data.filter(v => {
				if(v.id == 2410) console.log('aaaaaaaaaaaa')
				return v.id == id;
			})
			return hold.length ? true : false;
		},
		getClientRate(get_suggested = false, v_type_id = null){
			console.log('AAAGOII')
				// console.log('asd',this.bookingParams.vehicle_id)
				const vm = this;
				let vehicle_type_id = '';
				if(!v_type_id) {
					vehicle_type_id = this.vehicleList.data.filter((value, index)=>{
						return value.id == this.dataParams.vehicle_id;
					}); 
					if(vehicle_type_id.length > 0){
						vehicle_type_id = vehicle_type_id[0].vehicle_type_id
					}
					else{
						vehicle_type_id = ""
					}
				}
				else vehicle_type_id = v_type_id;
				
				if(get_suggested)
					this.suggested_rate = 0;

				this.computing_client_rate  = true;

				const params = {
					commodity_id: vm.dataParams.commodity_id,
					depot_id: vm.dataParams.depot_id,
					vehicle_type_id: vm.dataParams.vehicle_type_id,
					customer_id: vm.dataParams.customer_id,
					origin_id : vm.dataParams.origin_id,
					destination_id : vm.dataParams.destination_id
				}

				// axios.get(config.api_path+'/client_rate?commodity_id='+this.dataParams.commodity_id
				// +'&depot_id='+this.dataParams.depot_id
				// +'&vehicle_type_id='+vehicle_type_id
				// +'&customer_id='+this.dataParams.customer_id
				// +'&origin_id='+this.dataParams.origin_id
				// +'&destination_id='+this.dataParams.destination_id
				// +'&page=1&limit=10000000')
				axios.get(`${config.api_path}/reference/furthest-destination`, {
					params
				})
    			.then(response => {
					const {data} = response.data;
					console.log(response, response.data)
					if(!get_suggested) {
						console.log('ok')
						vm.options = data.map((value, index) => {
							value.setting_name = value.destination.setting_name;
							return value
						});
						
						if(data.length > 0){
							this.$set(this.dataParams,'client_rate', data[0].client_rate);
							this.$set(this.dataParams,'subcon_rate', data[0].subcon_rate);
							
						}
						else {
							this.$set(this.dataParams,'client_rate', 0);
							this.$set(this.dataParams,'subcon_rate', 0);
						}
					}
					else {
						
						if(data.length > 0){
							this.suggested_rate = data[0].client_rate;
						}
					}
					

				// console.log('HUHHHH??', this.dataParams.client_rate, data[0])
				this.computing_client_rate  = false;
    			}).catch(err => {
					console.error(err)
					this.computing_client_rate  = false;
				})
			},

				
		
			computeclientrate(value, type) {
				if(type == 'destination') {
					this.dataParams.destination_label = value?.destination ?? null;
					this.dataParams.destination_id = value?.destination_id ?? null;
				}
				console.log(value)

				const has_client_rate = this.dataParams.client_rate && parseFloat(this.dataParams.client_rate) > 0;
				const has_subcon_rate = this.dataParams.subcon_rate && parseFloat(this.dataParams.subcon_rate) > 0; 
				
				// if(!(has_client_rate || has_subcon_rate))
					this.getClientRate(false, this.dataParams.vehicle_type_id)

				this.getDestination()
			},
			
		truckTypeChange(value){
			// this.dataParams.vehicle_id = null
			// this.bookingParams.vehicle_type_id = value?.id ?? 0;
			// this.$set(this.dataParams,'vehicle_type_id',value);
			// console.log(this.bookingParams.vehicle_type_id, '----', this.bookingParams.vehicle_type_label)
		},
		vehicleChange(e){
			if(!e) {
				this.dataParams.vehicle_id = null;
				this.dataParams.vehicle_info = null;
				return;
			}

			this.dataParams.vehicle_id = e.id;
			this.dataParams.vehicle_info = e;
			
			var vehicle = this.vehicleList.data.filter((value, index)=>{
				return value.id == this.dataParams.vehicle_id;
			})

			if(vehicle.length) {
				if(this.dataParams.vehicle_id != ""){ 
					this.$set(this.dataParams,'vehicle_type_id',vehicle[0].vehicle_type_id) 
				}

				var vendor = this.vendorList.data.filter((value, index)=>{
					return vehicle[0].vendor_id == value.id;
				})
				if(vendor)
					this.$set(this.dataParams,'vendor_id',vehicle[0].vendor_id)
				else  
					this.$set(this.dataParams,'vendor_id',null)
			}
			
			console.log(this.dataParams.vendor_id,'sd')
			
		}, 
		vendorChange(){
			this.dataParams.vehicle_id = null
			this.dataParams.vehicle_type_id = null;
			this.getVehicle()
		},
		agencyChangeDriver(param){
			console.log('asssd', this.dataParams.agency)
			this.dataParams.driver_id = null;
			this.driverList = {...this.driverListHolder};

			this.dataParams.agency = param;

			if(this.dataParams.agency?.id)
				this.driverList.data = this.driverList.data.filter(val => val.vendor_id === this.dataParams.agency.id)
			else {
				if(!this.dataParams.is_subcon) 
					this.driverList.data = this.driverList.data.filter(value => value.employment_type.toLowerCase() == 'outsourced'  && value.vendor.vendor_type_name.setting_name.toLowerCase() == 'manpower')
				else 
					this.driverList.data = this.driverList.data.filter(value => value.employment_type == this.dataParams.assigned_personnel)
			}
		}, 
		agencyChangeHelper1(param){
			console.log('agencyChangeHelper1',param)
			this.dataParams.helper_a_id = null;
			this.dataParams.helper_a = {};
			this.helperList = {...this.helperListHolder};
			this.dataParams.driver_included1 = false;

			this.dataParams.helper1agency = {...param};

			if(this.dataParams.helper1agency?.id)
				this.helperList.data = this.helperList.data.filter(val => val.vendor_id === this.dataParams.helper1agency.id)
			else {
				if(!this.dataParams.is_subcon) 
					this.helperList.data = this.helperList.data.filter(value => value.employment_type == 'outsourced'  && value.vendor.vendor_type_name.setting_name.toLowerCase() == 'manpower')
				else 
					this.helperList.data = this.helperList.data.filter(val => val.employment_type === this.dataParams.assigned_personnel_helper_1)
			}

			console.log('ok')
				
		}, 
		agencyChangeHelper2(param){
			console.log('agencychangeHelper2', param)
			this.dataParams.helper_b_id = null;
			this.helperList2 = {...this.helperListHolder};
			this.dataParams.driver_included2 = false;

			this.dataParams.helper2agency = param;

			if(this.dataParams.helper2agency?.id)
				this.helperList2.data = this.helperList2.data.filter(val => val.vendor_id === this.dataParams.helper2agency.id)
			else {
				if(!this.dataParams.is_subcon) 
					this.helperList2.data = this.helperList2.data.filter(value => value.employment_type == 'outsourced'  && value.vendor.vendor_type_name.setting_name.toLowerCase() == 'manpower')
				else 
					this.helperList2.data = this.helperList2.data.filter(val => val.employment_type === this.dataParams.assigned_personnel_helper_2)
			}
		}, 
		driverChange(param) {
			console.log(param,'aa')
			let driver_info = null;
			this.dataParams.agency = null;
			this.dataParams.agency_id = null;

			this.dataParams.driver_id = param?.id ?? null;
			this.dataParams.driver = param;
			// this.$set(this.dataParams,'driver_label', param?.name ?? null)
			// this.dataParams.driver_label = param.name;
			for(const d of this.driverList.data) {
				if(d.id == this.dataParams.driver_id) {
					driver_info = d;
					break;
				}
			}
			if(driver_info?.vendor_id) {
				for(const a of this.agencyList.data) {
					if(a.id == driver_info.vendor_id) {
						this.dataParams.agency_id = a.id;
						this.dataParams.agency = a;
						break
					}
				}
			}
			
		},
		helper1Change(param) {
			let helperInfo = null;
			
			this.dataParams.helper1agency = null;
			
			if(!param) {
				this.dataParams.helper_a_id = null;
				this.dataParams.helper_a = {}
				return;
			}
			this.dataParams.helper_a_id = param.id;
			this.dataParams.helper_a_label = param.name;
			this.dataParams.helper_a = param;
			for(const d of this.helperList.data) {
				if(d.id == this.dataParams.helper_a_id) {
					helperInfo = d;
					break;
				}
			}
			if(helperInfo?.vendor_id) {
				for(const a of this.agencyList.data) {
					if(a.id == helperInfo.vendor_id) {
						this.dataParams.agency_id_helper1 = a.id;
						this.dataParams.helper1agency = a;
						break
					}
				}
			}
			
		},
		helper2Change(param) {
			let helperInfo = null;
			this.dataParams.helper2agency = null;
			if(!param) {
				this.dataParams.helper_b_id = null;
				this.dataParams.helper_b = {}
				return;
			}
			this.dataParams.helper_b_id = param.id;
			this.dataParams.helper_b_label = param.name;
			this.dataParams.helper_b = param;
			for(const d of this.helperList2.data) {
				if(d.id == this.dataParams.helper_b_id) {
					helperInfo = d;
					break;
				}
			}
			if(helperInfo?.vendor_id) {
				for(const a of this.agencyList.data) {
					if(a.id == helperInfo.vendor_id) {
						this.dataParams.agency_id_helper2 = a.id;
						this.dataParams.helper2agency = a;
						break
					}
				}
			}
		},

		getPersonnelRate(){ 
			axios.get(config.api_path+'/personnel_rate?depot_id='+this.dataParams.depot_id+'&customer_id='+this.dataParams.customer_id+'&page=1&limit=20&page=1&limit=10000000')
			.then(response => {
				if(response.data.data.length > 0){
					// this.bookingParams.client_rate = response.data.data[0].client_rate;
					// this.bookingParams.subcon_rate = response.data.data[0].subcon_rate;

					if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
						this.dataParams.driver_rate =  (this.dataParams.driver_rate) ? this.dataParams.driver_rate : this.dataParams.driver.daily_rate;
						this.dataParams.helper_a_rate =  (this.dataParams.helper_a_rate) ? this.dataParams.helper_a_rate : this.dataParams.helper_a.daily_rate;
						this.dataParams.helper_b_rate =  (this.dataParams.helper_b_rate) ? this.dataParams.helper_b_rate : this.dataParams.helper_b.daily_rate;
					}
					else {
						if(parseInt(this.dataParams.driver_rate) == 0 || this.dataParams.driver_rate == null){
							this.dataParams.driver_rate = response.data.data[0].delivery_rate;
						}
						if(parseInt(this.dataParams.helper_a_rate) == 0 || this.dataParams.helper_a_rate == null){
							this.dataParams.helper_a_rate = response.data.data[0].helper_rate;
						}
						if(parseInt(this.dataParams.helper_b_rate) == 0 || this.dataParams.helper_b_rate == null){
							this.dataParams.helper_b_rate = response.data.data[0].helper_rate;
						}
					}

					if(parseInt(this.dataParams.helper_a_allowance) == 0 || this.dataParams.helper_a_allowance == null){
						this.dataParams.helper_a_allowance = response.data.data[0].helper_allowance;
					}
					if(parseInt(this.dataParams.helper_b_allowance) == 0 || this.dataParams.helper_b_allowance == null){
						this.dataParams.helper_b_allowance = response.data.data[0].helper_allowance;
					}
					if(parseInt(this.dataParams.driver_allowance) == 0 || this.dataParams.driver_allowance == null){
						this.dataParams.driver_allowance = response.data.data[0].driver_allowance;
					}    

				}
			})
		},
 
 	},

 	watch: {
		 'dataParams.vehicle_id' : function(val) {
			//  var vehicle = this.vehicleList.data.filter((value, index)=>{
			// 		return value.id == this.dataParams.vehicle_id;
			// 	})
			// 	if(this.dataParams.vehicle_id != ""){ 
 			// 		if(vehicle.length > 0){
 			// 			this.$set(this.dataParams,'vehicle_type_id',vehicle[0].vehicle_type_id) 
 			// 		} 
 			// 	}
			},
		dataParams: {
			
 			handler(val, oldVal){
 			
 				var vehicle = this.vehicleList.data.filter((value, index)=>{
					return value.id == this.dataParams.vehicle_id;
				})

 				// if(this.dataParams.vehicle_id != ""){ 
 				// 	if(vehicle.length > 0){
 				// 		this.$set(this.dataParams,'vehicle_type_id',vehicle[0].vehicle_type_id) 
 				// 	} 
 				// }
 				for(var i = 0; i < this.equipmentList.data.length; i++){ 
 					if(this.equipmentList.data[i].id == this.dataParams.equipment_id){ 
 						this.equipmentDetails = this.equipmentList.data[i];
 						this.equipmentDetails.asset_no = this.equipmentDetails.asset_no ? this.equipmentDetails.asset_no : "N/A"
 						this.equipmentDetails.serial_no = this.equipmentDetails.serial_no ? this.equipmentDetails.serial_no : "N/A"
 						this.equipmentDetails.mfg_brand = this.equipmentDetails.mfg_brand ? this.equipmentDetails.mfg_brand : "N/A"
 						this.equipmentDetails.model = this.equipmentDetails.model ? this.equipmentDetails.model : "N/A"
 						this.equipmentDetails.mts_no = this.equipmentDetails.mts_no ? this.equipmentDetails.mts_no : "N/A"  
 						this.equipmentDetails.type = this.equipmentDetails.type ? this.equipmentDetails.type : "N/A"  
 						this.equipmentDetails.specification = this.equipmentDetails.specification ? this.equipmentDetails.specification : "N/A"
 					}
 				}

 				for(var i = 0; i < this.furnishingList.data.length; i++){ 
 					if(this.furnishingList.data[i].id == this.dataParams.furnishing_id){ 
 						this.furnishingDetails = this.furnishingList.data[i];
 						this.furnishingDetails.asset_no = this.furnishingDetails.asset_no ? this.furnishingDetails.asset_no : "N/A"
 						this.furnishingDetails.serial_no = this.furnishingDetails.serial_no ? this.furnishingDetails.serial_no : "N/A"
 						this.furnishingDetails.mfg_brand = this.furnishingDetails.mfg_brand ? this.furnishingDetails.mfg_brand : "N/A"
 						this.furnishingDetails.model = this.furnishingDetails.model ? this.furnishingDetails.model : "N/A"
 						this.furnishingDetails.mts_no = this.furnishingDetails.mts_no ? this.furnishingDetails.mts_no : "N/A"  
 						this.furnishingDetails.type = this.furnishingDetails.type ? this.furnishingDetails.type : "N/A"  
 						this.furnishingDetails.specification = this.furnishingDetails.specification ? this.furnishingDetails.specification : "N/A"
 					}
 				}
 			},
 			deep: true
 		},
		
		 'dataParams.billable.parking_fee': function(val) {
			if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				let amount = !isNaN(val) && val.length ? parseFloat(val) : 0;
				if(amount > 1000)
					this.$set(this.error_list,'parking-fee', 'Parking Fee should not exceed 1,000');
				else
					this.$delete(this.error_list,'parking-fee');
			}        
        },
        'dataParams.billable.toll_fee': function(val) {
			if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				let amount = !isNaN(val) && val.length ? parseFloat(val) : 0;
				if(amount > 5000)
					this.$set(this.error_list,'toll-fee', 'Toll Fee should not exceed 5,000');
				else
					this.$delete(this.error_list, 'toll-fee');
			}
        },
        'dataParams.driver_allowance': function(val) {
			if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				let amount = !isNaN(val) && val.length ? parseFloat(val) : 0;
				if(amount > 3000)
					this.$set(this.error_list,'driver-allowance', 'Driver allowance should not exceed 3,000');
				else
					this.$delete(this.error_list, 'driver-allowance');
			}
        },
        'dataParams.helper_a_allowance': function(val) {
			if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				let amount = !isNaN(val) && val.length ? parseFloat(val) : 0;
				if(amount > 2000)
					this.$set(this.error_list,'helper1-allowance', 'Helper allowance should not exceed 2,000');
				else
					this.$delete(this.error_list, 'helper1-allowance');
			}
        },
        'dataParams.helper_b_allowance': function(val) {
			if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				let amount = !isNaN(val) && val.length ? parseFloat(val) : 0;
				if(amount > 2000)
					this.$set(this.error_list,'helper2-allowance', 'Helper allowance should not exceed 2,000');
				else
                	this.$delete(this.error_list, 'helper2-allowance');
			}
        },
		'dataParams.fuel_amount': function(val) {
			if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				let amount = !isNaN(val) && val.length ? parseFloat(val) : 0;
				if(amount > 6000)
					this.$set(this.error_list,'fuel-amount', 'Fuel amount should not exceed 6,000');
				else
					this.$delete(this.error_list, 'fuel-amount');
			}
        },
		'dataParams.fuel': function(val) {
			if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				let amount = !isNaN(val) && val.length ? parseFloat(val) : 0;
				if(amount > 80)
					this.$set(this.error_list,'fuel', 'Fuel should not exceed 80 Liters');
				else
					this.$delete(this.error_list, 'fuel');
			}
        },
		'dataParams.driver_rate': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				if(this.dataParams.status == 'completed') {
					let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
					if(amount <= 0)
						this.$set(this.error_list,'driver-rate', 'Driver rate must be greater than 0');
					else
						this.$delete(this.error_list, 'driver-rate');
				}
                
            }
        },
        'dataParams.helper_a_rate': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				if(this.dataParams.status == 'completed' && this.dataParams.helper_a_id) {
					let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
					if(amount <= 0)
						this.$set(this.error_list,'helper1-rate', 'Helper rate must be greater than 0');
					else
						this.$delete(this.error_list, 'helper1-rate');
				}   
            }
        },
        'dataParams.helper_b_rate': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
				if(this.dataParams.status == 'completed' && this.dataParams.helper_b_id) {
					let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
					if(amount <= 0)
						this.$set(this.error_list,'helper2-rate', 'Helper rate must be greater than 0');
					else
						this.$delete(this.error_list, 'helper2-rate');
				}
			}
        },

 	}

}
</script>
<style>
	.btn_upload {
		width: 200px;
		height: 200px;
		background: #fff;
		text-align: center;
		border-style: dashed;
		border-radius: 10px;
		padding:10px
	}
	.img-view {
		width: 100%;
		height: 100%;
		background-position:center;
		background-size:cover
		
	}
	.img-upload-container {
		align-items: center;
		display:flex
	}
	.img-view img {
		width: 100px;
		margin-top: 25px;
		opacity: 0.4
	}
	.img-view span {
		display: block;
		font-size:12px;
		margin-top:15px;
		color: #777
	}
</style>